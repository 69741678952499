<template>
  <div id="login">
    <img class="logo" style="" src="../../assets/bowinzlogo.png" alt="logo" />
    <div class="cont">
      <transition name="bounce">
        <div class="content">
          <p>找回密码</p>
          <div class="main">
            <a-input v-model="randomcode" placeholder="请输入邮箱收到的验证码">
              <i slot="prefix" class="iconfont icon">&#xe605;</i>
            </a-input>
          </div>
          <div class="main">
            <a-input
              v-model="password"
              type="password"
              placeholder="请输入您的新密码"
            >
              <i slot="prefix" class="iconfont icon">&#xe60f;</i>
            </a-input>
          </div>
          <div class="main">
            <a-input
              v-model="password2"
              type="password"
              placeholder="请确认您的新密码"
            >
              <i slot="prefix" class="iconfont icon">&#xe60f;</i>
            </a-input>
          </div>
          <div class="main">
            <el-button type="warning" size="medium" @click="updatePassword">
              确认
            </el-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script>
import { EmailUpdatePassword } from "../../api/Employee";

export default {
  name: "login",
  data() {
    return {
      loading: "",
      sid: "",
      randomcode: "",
      password: "",
      password2: "",
      email: "",
    };
  },
  mounted() {
    // 绑定enter事件
    this.enterKeyup();
    this.getUrlKey();
  },
  destroyed() {
    // 销毁enter事件
    this.enterKeyupDestroyed();
  },
  methods: {
    getUrlKey() {
      let src = location.href;
      let data = {};
      //let index = src.indexOf("?");
      let dataStr = src.substring(src.indexOf("?") + 1);
      let dataArr = dataStr.split("&");
      dataArr.forEach((str) => {
        let param = str.split("=");
        data[param[0]] = param[1];
      });
      this.sid = data.sid;
      console.log(this.sid);
    },
    enterKey(event) {
      if (event.keyCode === 13) {
        this.updatePassword();
      }
    },
    enterKeyupDestroyed() {
      document.removeEventListener("keyup", this.enterKey);
    },
    enterKeyup() {
      document.addEventListener("keyup", this.enterKey);
    },
    openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    updatePassword() {
      this.openLoading();
      if (this.randomcode === "") {
        this.loading.close();
        this.$message.warning("请输入验证码！");
      } else if (this.password === "") {
        this.loading.close();
        this.$message.warning("请输入新密码！");
      } else if (this.password !== this.password2) {
        this.loading.close();
        this.$message.warning("两次输入的密码不匹配！");
      } else {
        const param = {
          LicenseKey: this.sid,
          RandomCode: this.randomcode,
          Password: this.password,
        };
        EmailUpdatePassword(param)
          .then((res) => {
            // console.log(res)
            if (res.result.bRet) {
              this.loading.close();
              this.$router.push({ name: "Login" });
              // this.$router.push({name: 'FillIn'})
            } else {
              this.loading.close();
              this.$message.error("修改失败！");
            }
            // console.log(res.result)
          })
          .catch((err) => {
            if (err.message === "timeout of 6000ms exceeded") {
              this.$message.error("超时，请联系管理员");
            }
            this.loading.close();
            console.log(err);
          });
      }
    },
  },
};
</script>
  
  <style lang="stylus" scoped>
  #login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-image: url('../../assets/20221123152934.png');
    background-size: 100% 100%;
    padding: 30px 200px 0 30px;
    box-sizing: border-box;
    justify-content: space-between;

    .logo {
      width: 160px;
      height: 100px;
    }

    .cont {
      height: 100%;
      display: grid;
      align-items: center;
    }

    .content {
      width: 504px;
      height: 550px;
      background-color: rgb(255, 255, 255);
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 0 6px rgba(120, 120, 120, 0.1);
      padding: 40px 42px 30px 42px;
      grid-area: 1 / 1 / 2 / 2;

      p {
        font-size: 32px;
        font-weight: bold;
        color: rgb(253, 126, 22);
        margin-left: 22px;
      }

      .forget-password {
        font-size: 18px;
        font-weight: bold;
        color: #F07106;
        float: right;
        cursor: pointer;
        margin-top: 20px;
      }

      .main {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        margin-top: 40px;

        .user_icon {
          width: 27px;
          height: 27px;
          font-size: 18px;
          position: relative;
          top: -43px;
          left: 28px;
        }
      }

      .main1 {
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        margin-top: 76px;
      }

      .remind {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 30px;
        box-sizing: border-box;
        font-size: 14px;
        color: #ff8000;
        text-shadow: 2px 2px 3px #999;
      }
    }
  }

  .el-input >>> .el-input__inner, .el-input .el-input__clear {
    border: none !important;
    background: #FAFAFA !important;
    padding-left: 80px !important;
    padding-right: 70px;
    height: 64px;
    font-size: 18px;
    color: #999999;
  }

  .el-button {
    width: 100%;
    height: 64px;
    font-size: 22px;
    background-color: rgb(255, 103, 31);
    border-radius: 44px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.8s;
  }

  .bounce-leave-active {
    animation: bounce-in 0.8s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  /deep/ .ant-input {
    background: #FAFAFA;
    border-radius: 8px;
    padding-left: 70px !important;
    height: 64px;
    font-size: 18px;
    color: #999999;
    border: none !important;
    box-sizing: border-box;
  }

  .icon {
    font-size: 30px;
    color: #333333;
    margin-left: 10px;
  }

  .icon1 {
    font-size: 30px;
    color: #333333;
    cursor: pointer;
  }
</style>